var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useGetPopularListQuery } from '@shared/api/portfolio';
import { mapPortfoliosListFromResponse } from '../lib';
const QUOTATIONS = [{
        valueToSell: 'USDT', valueToBy: 'BTC', percentage: 1.58, trend: 'down', price: '$ 25.882',
    }, {
        valueToSell: 'USDT', valueToBy: 'BTC', percentage: 1.58, trend: 'down', price: '$ 25.882',
    },
    {
        valueToSell: 'USDT', valueToBy: 'BTC', percentage: 1.58, trend: 'down', price: '$ 25.882',
    },
    {
        valueToSell: 'USDT', valueToBy: 'ETH', percentage: 2.11, trend: 'up', price: '$ 1.489',
    }];
const useMarketplaceInfo = () => {
    const { isLoading, currentData: portfoliosList } = useGetPopularListQuery(undefined, {
        selectFromResult: (_a) => {
            var { currentData } = _a, rest = __rest(_a, ["currentData"]);
            return (Object.assign(Object.assign({}, rest), { currentData: currentData ? mapPortfoliosListFromResponse(currentData) : undefined }));
        },
    });
    return {
        quotations: QUOTATIONS,
        portfolioList: isLoading ? [] : portfoliosList,
    };
};
export default useMarketplaceInfo;
