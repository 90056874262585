export const SUBSCRIPTION_DATA_INIT = {
    investAmount: 0,
    stopCopyingLimit: 15,
    subscriptionPrice: 0,
    minInvestAmount: 0,
    walletId: null,
    queuePosition: 1,
};
export const SUBSCRIPTION_STEPS_INIT = {
    select_exchange: {
        status: 'uninitialized',
        order: 0,
    },
    invest_amount: {
        status: 'uninitialized',
        order: 1,
    },
    queue_priority: {
        status: 'uninitialized',
        order: 2,
    },
    payment_method: {
        status: 'uninitialized',
        order: 3,
    },
};
