import { createSlice } from '@reduxjs/toolkit';
import { PortfolioType } from '../../model/Portfolio';
import { PortfolioBalanceType, PortfolioVisibility } from './constants';
export const SliceName = 'portfolioEdit';
const CreateEmptyPortfolioEditBody = () => ({
    parentExchangeId: -1,
    name: '',
    type: PortfolioType.Spot,
    visibility: PortfolioVisibility.Visible,
    referalLink: 'https://endrex.net',
    balance: PortfolioBalanceType.Usdt,
    monthlyFee: 0,
    feeCurrency: 'usd', // TODO: discuss
});
const portfolioEditSlice = createSlice({
    name: SliceName,
    initialState: {
        editBody: CreateEmptyPortfolioEditBody(),
    },
    reducers: {
        updateParentExchange: (state, action) => {
            state.editBody.parentExchangeId = action.payload;
        },
        updateName: (state, action) => {
            state.editBody.name = action.payload;
        },
        updateType: (state, action) => {
            state.editBody.type = action.payload;
        },
        updateVisibility: (state, action) => {
            state.editBody.visibility = action.payload;
        },
        updateMonthlyFee: (state, action) => {
            state.editBody.monthlyFee = action.payload;
        },
    },
});
export const { updateParentExchange, updateName, updateType, updateVisibility, updateMonthlyFee, } = portfolioEditSlice.actions;
export const portfolioEditReducer = portfolioEditSlice.reducer;
