var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IsDefined } from '@shared/lib/js-ts';
import portfolioApi from '@shared/api/portfolio';
const subscribeToPortfolio = createAsyncThunk('portfolioSubscription/subscribe', (_1, _a) => __awaiter(void 0, [_1, _a], void 0, function* (_, { getState, dispatch, rejectWithValue }) {
    const { portfolio, subscriptionData } = getState().portfolioSubscription;
    if (!IsDefined(portfolio) || !IsDefined(subscriptionData)) {
        // TODO: handle
        return rejectWithValue(['unknown']);
    }
    const res = yield dispatch(portfolioApi.endpoints.startCopying.initiate({
        portfolioId: portfolio.id,
        body: subscriptionData,
    }));
    if ('data' in res) {
        // eslint-disable-next-line consistent-return
        return res;
    }
    return rejectWithValue(['unknown']);
}));
export { 
// eslint-disable-next-line import/prefer-default-export
subscribeToPortfolio, };
