import { IsDefined } from '@utils/js-ts';
const isUserPortfolioOwner = (portfolio, user) => {
    if (!IsDefined(portfolio) || !IsDefined(user)) {
        return false;
    }
    return (portfolio.profileId === user.activeProfileId ||
        portfolio.profileId === user.unactiveProfileId);
};
const isUserSubsbribedToPortfolio = (portfolio, user) => {
    if (!IsDefined(portfolio) || !IsDefined(user)) {
        return false;
    }
    return !!portfolio.user_subscription_summary;
};
const mapPortfolioFromResponse = (portfolio) => {
    var _a, _b, _c;
    return (Object.assign(Object.assign({}, portfolio), { profile: Object.assign({}, portfolio.profile), type: ((_a = portfolio.wallet) === null || _a === void 0 ? void 0 : _a.type) || 'SPOT', logo: ((_c = (_b = portfolio.profile) === null || _b === void 0 ? void 0 : _b.profilePicture) === null || _c === void 0 ? void 0 : _c.avatar) || '', 
        // chat_activity: portfolio.portfoliocommentSet?.length,
        chat_activity: 0, 
        // copiers_count: portfolio.copyingSet?.length || 100,
        copiers_count: 0, 
        // viewed: portfolio.portfolioviewSet?.length,
        viewed: 0, 
        // parent_exchange_id:
        //     portfolio.exchangeConnectionPortfolio?.length ?
        //         portfolio.exchangeConnectionPortfolio[0].id : '0',
        referral_asset: '', 
        // TODO add field after readiness on backend
        favorited_count: 0, shared_count: 0, min_balance: 0, graph_data: [], minimum_balance_type: 'fifth_part', overall_difference: 0, past_week_difference: 20.55, past_month_difference: 15.81, past_ninety_days_difference: 18.71, whole_period_days_count: 150, traders_balance: 250, past_month_start_date: '2023-11-15T13:09:56.276376+00:00', past_month_end_date: '2023-10-15T14:09:56.276376+00:00', followers: {
            count: 0,
            // count: Number(portfolio.profile?.followersCount),
            average_followers_monthly_return: 0,
            // average_followers_monthly_return: 15.22,
            average_followers_monthly_return_first: 0,
            // average_followers_monthly_return_first: 16.00,
            average_followers_monthly_return_last: 0,
            // average_followers_monthly_return_last: 15.00,
            preview_followers: [],
        }, visibility: 'public', is_archived: false, has_error: false }));
};
const mapClosedPortfolioFromResponse = (portfolio) => (Object.assign(Object.assign({}, mapPortfolioFromResponse(portfolio)), { is_archived: true }));
const mapPortfoliosListFromResponse = (portfoliosList) => portfoliosList.map(mapPortfolioFromResponse);
// TODO-FIX: temporary variable
const mapClosedPortfoliosListFromResponse = (portfoliosList) => portfoliosList.map(mapClosedPortfolioFromResponse);
const mapSubscribedPortfoliosFromResponse = (subscribedPortfolios) => subscribedPortfolios.map((subscribedPortfolio) => mapPortfolioFromResponse(subscribedPortfolio.portfolio));
// should display all portfolios to the portfolio owner even if assessment not passed
const shouldFilterByAssessmentPassed = ({ portfolioProfileId, currentUser = undefined, isVerifiedTrader, }) => {
    const isPortfolioOwner = isUserPortfolioOwner({ profileId: portfolioProfileId || '' }, currentUser);
    if (isPortfolioOwner) {
        return false;
    }
    return !isVerifiedTrader;
};
export { isUserPortfolioOwner, isUserSubsbribedToPortfolio, mapPortfolioFromResponse, mapClosedPortfoliosListFromResponse, mapPortfoliosListFromResponse, mapSubscribedPortfoliosFromResponse, shouldFilterByAssessmentPassed, };
