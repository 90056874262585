var PortfolioEditViewMode;
(function (PortfolioEditViewMode) {
    PortfolioEditViewMode[PortfolioEditViewMode["View"] = 0] = "View";
    PortfolioEditViewMode[PortfolioEditViewMode["Edit"] = 1] = "Edit";
})(PortfolioEditViewMode || (PortfolioEditViewMode = {}));
var PortfolioVisibility;
(function (PortfolioVisibility) {
    PortfolioVisibility["Visible"] = "visible";
    PortfolioVisibility["Private"] = "private";
})(PortfolioVisibility || (PortfolioVisibility = {}));
var PortfolioBalanceType;
(function (PortfolioBalanceType) {
    PortfolioBalanceType["Percentage"] = "percentage";
    PortfolioBalanceType["Usdt"] = "usdt";
})(PortfolioBalanceType || (PortfolioBalanceType = {}));
export { PortfolioBalanceType, PortfolioEditViewMode, PortfolioVisibility, };
